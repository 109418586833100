<template>
  <div style="padding-top: 70px">
    <img src="https://img1.hisforce.cc/sb%402x.png" width="64" height="64" style="margin-bottom: 10px"/>
    <h3>支付失败</h3>
    <p style="color: #FF2700;font-size:10px">温馨提示：请返回看牙帮小程序【我的】个人中心-待付款页面重新支付</p>
  </div>
</template>

<script>
export default {
  name: "error"
};
</script>

<style scoped>

h3{
  margin: 0 auto 40px auto;
}
</style>
