<template>
  <div>

  </div>
</template>
<script>

export default {
  name: "thirdPay",
  data(){
    return {

    }
  },
  created() {
    console.log("create")
    // let _url = this.getUrlKey('url')
    console.log(window.location.search,this.$route.query.url)
    window.location.href = this.$route.query.url
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style scoped>

h3{
  margin: 0 auto 40px auto;
}
</style>
