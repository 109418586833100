<template>
  <div class="about">
<!--    <h1>This is an about page</h1>-->
    <div style="padding-top: 70px">
      <img src="https://img1.hisforce.cc/cg%402x.png" width="64" height="64" style="margin-bottom: 10px"/>
      <h3>支付成功</h3>
      <p style="color: #FF2700;font-size:10px">温馨提示：请进入看牙帮小程序查看订单状态</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'about',
  created() {
    let url = location;
    console.log(url,'url');
  }
}
</script>
<style scoped>
.about{
  min-height: 100%;
  overflow: hidden;
}
h3{
  margin: 0 auto 40px auto;
}
</style>
